@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('IBM Plex Sans'), local('IBMPlexSans'),
    url('src/assets/fonts/IBMPlexSans-Regular.woff2') format('woff2'),
    url('src/assets/fonts/IBMPlexSans-Regular.woff') format('woff');
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('IBM Plex Sans Italic'), local('IBMPlexSans-Italic'),
    url('src/assets/fonts/IBMPlexSans-Italic.woff2') format('woff2'),
    url('src/assets/fonts/IBMPlexSans-Italic.woff') format('woff');
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('IBM Plex Sans Light'), local('IBMPlexSans-Light'),
    url('src/assets/fonts/IBMPlexSans-Light.woff2') format('woff2'),
    url('src/assets/fonts/IBMPlexSans-Light.woff') format('woff');
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('IBM Plex Sans Light Italic'), local('IBMPlexSans-LightItalic'),
    url('src/assets/fonts/IBMPlexSans-LightItalic.woff2') format('woff2'),
    url('src/assets/fonts/IBMPlexSans-LightItalic.woff') format('woff');
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
    url('src/assets/fonts/IBMPlexSans-SemiBold.woff2') format('woff2'),
    url('src/assets/fonts/IBMPlexSans-SemiBold.woff') format('woff');
}

@font-face {
  font-family: IBM Plex Sans;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('IBM Plex Sans SemiBold Italic'),
    local('IBMPlexSans-SemiBoldItalic'),
    url('src/assets/fonts/IBMPlexSans-SemiBoldItalic.woff2') format('woff2'),
    url('src/assets/fonts/IBMPlexSans-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('IBM Plex Mono'), local('IBMPlexMono'),
    url('src/assets/fonts/IBMPlexMono-Regular.woff2') format('woff2'),
    url('src/assets/fonts/IBMPlexMono-Regular.woff') format('woff');
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('IBM Plex Mono Light'), local('IBMPlexMono-Light'),
    url('src/assets/fonts/IBMPlexMono-Light.woff2') format('woff2'),
    url('src/assets/fonts/IBMPlexMono-Light.woff') format('woff');
}

@font-face {
  font-family: IBM Plex Mono;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('IBM Plex Mono SemiBold'), local('IBMPlexMono-SemiBold'),
    url('src/assets/fonts/IBMPlexMono-SemiBold.woff2') format('woff2'),
    url('src/assets/fonts/IBMPlexMono-SemiBold.woff') format('woff');
}
